import { Button, Checkbox, Layout } from '@clout-team/web-components'
import React, { useEffect, useMemo, useState } from 'react'
import {
  sendMessageAndAddToContacts,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { IFriend, useLoginActions } from '../../state/slices/login'
import CardContact from '../CardContact/CardContact'
import { SendFirstMessageModal } from '../SendMessageModal/SendMessageModal'
import './AddFriends.scss'
import { capitalize } from '../../shared/lib/functions'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import Loader from '../../shared/ui/Loader/Loader'

interface IContacts extends IFriend {
  chosen?: boolean
}

function sortContactsByStatus (a: IContacts, b: IContacts) {
      if (a.contact_status === 'FRIEND' && b.contact_status !== 'FRIEND') {
        return -1;
      } else if (a.contact_status !== 'FRIEND' && b.contact_status === 'FRIEND') {
        return 1;
      } else {
        return 0;
      }
  }

export const AddFriendsWeb: React.FC = () => {
  const { t } = useTranslation()

  const { setStep } = useLoginActions()
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.login.token)
  const friends: IFriend[] = useAppSelector((state) => state.login.friends)
  const [contacts, setContacts] = useState<IContacts[] | []>(friends)
  const [valid, setValid] = useState<number>(0)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)

  const isLoadingFriends: boolean = useAppSelector(
    (state) => state.login.is_loading_friends,
  )

  const sortedContacts = useMemo(() => {
    return [...contacts].sort(sortContactsByStatus)
  }, [contacts])

  const onSkipHandler = () => {
    setStep('FINISH')
  }

  const onAddContactsHandler = (text: string) => {
    if (valid !== 0) {
      const arr = contacts
        .filter((el) => {
          if (el.chosen) {
            return el
          }
        })
        .map((el) => {
          return el.hash
        })
      dispatch(sendMessageAndAddToContacts({ ids: arr, message: text, token: token }))
    }
  }

  const onCardClickHandler = (el: IContacts) => {
    if (el.is_friend) return
    setContacts((prev) => [
      ...prev.map((contact: IContacts) => {
        if (allSelected) {
          setAllSelected(false)
        }
        if (contact.hash === el.hash) {
          return { ...contact, chosen: !contact.chosen }
        }
        return contact
      }),
    ])
    setIsChanged((prev) => !prev)
  }

  const onChoseAllHandler = () => {
    if (allSelected) {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: false,
          }
        }),
      ])
      setValid(0)
      setAllSelected(false)
      return
    } else {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: !el.is_friend,
          }
        }),
      ])
      setValid(contacts.length)
      setAllSelected(true)
    }
    setIsChanged((prev) => !prev)
  }

  useEffect(() => {
    let num: number = 0
    setValid(0)
    contacts.map((el) => {
      if (el.chosen === true) {
        num++
      }
    })
    setValid(num)
    if (num > 0 && num === contacts.length && !isLoadingFriends) {
      setAllSelected(true)
    }
  }, [isChanged])

  useEffect(() => {
    setContacts(friends)
  }, [friends])

  return (
    !isOpen ? (
      <div className={'addf_friends'}>
        <div className={'addf_friends__heading'}>
          <h2 className={'addf_friends__title'}>{t('add-friends.title')} 👥</h2>
          <p className={'addf_friends__description'}>
            {t('auth.new.friends.description')}
          </p>
        </div>
        <div className='addf_friends_scroll_bar'>
          {!isLoadingFriends && (
            <div className={'addf_friends__checkbox_wrapper'}>
              <Checkbox
                onClick={onChoseAllHandler}
                isChecked={allSelected}
                title={t('add-friends.select-all')}
              />
            </div>
          )}
          <div className={'addf_friends__wrapper'}>
            {isLoadingFriends ? (
              <Layout
                isAlignCenter
                className={'add_friends_mob__loader'}
                justifyContent='center'
              >
                {/* <Loading isNewDesign color={'white'} size={'md'} /> */}
                <Loader/>
              </Layout>
            ) : (
              sortedContacts.map((el: IContacts) => {
                return (
                  <CardContact
                    key={el.hash}
                    onClick={() => onCardClickHandler(el)}
                    isSelected={el.chosen}
                    title={el.name + ' ' + el.surname}
                    avatar={el.avatar}
                    isFriend={el.is_friend || el?.contact_status === 'FRIEND'}
                    description={capitalize(
                      el.department ? el.department.title : el.domain,
                    )}
                  />
                )
              })
            )}
          </div>
        </div>
        <div className={'addf_friends__footer'}>
          <Button
            size={'lg'}
            className={'addf_friends__button_skip'}
            color='white'
            onClick={onSkipHandler}
          >
            {t('auth.new.friends.skip')}
          </Button>
          <Button
            size={'lg'}
            className={'addf_friends__button_add'}
            disabled={valid === 0}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {t('add-friends.add')}
            {valid > 0 ? ` (${valid})` : ''}
          </Button>
        </div>
      </div>
    ) : (
        <SendFirstMessageModal
          onClose={() => setIsOpen((prev) => !prev)}
          onSubmit={(text) => onAddContactsHandler(text)}
        />
    )
  )
}
